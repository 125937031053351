@import "variables";

.u-text-heavy {
  font-weight: 700;
}

.u-text-normal {
  font-weight: 400;
}


.u-text-heading {
  font-family: $font-family-heading;
}

// Text sizes

.u-text-xxl {
  font-size: $font-size-xxlarge;
}

h1, .u-text-xl {
  font-size: $font-size-xlarge;
}

h2, .u-text-l {
  font-size: $font-size-large;
}

h3, .u-text-m {
  font-size: $font-size-medium;
}

h4, .u-text-s {
  font-size: $font-size-small;
}

h5, .u-text-xs {
  font-size: $font-size-xsmall;
}

h6, .u-text-xxs {
  font-size: $font-size-xxsmall;
}

.u-text-xxxs {
  font-size: $font-size-xxxsmall;
}

// Horizontal text alignment

.u-text-center {
  text-align: center;
}

.u-text-start {
  text-align: left;
}

.u-text-end {
  text-align: right;
}


.u-text-uppercase {
  text-transform: uppercase;
}

