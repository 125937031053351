.records__item {
  float: left;
  width: 250px;
}

.records__item--latest {
  width: 527px;
}

.records__artwork {
  font-size: 0;
}

.records__image {
  width: 100%;
}

.records__info {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.records__item--latest .records__info {
  position: absolute;
  bottom: 0;
  left: 0;
  background: white;
}

.records__latest-blurb {
  background: white;
}