.header {
	width: 100%;
  min-width: 900px;
	border-bottom: 1px solid rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(197,239,255, 0.95);
}

.header__start {
	width: 250px;
}

.header__start, .header__end {
	float: left;
}