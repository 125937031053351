@import 'variables';

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
	background: $body-background-color;
	margin: 0;
	margin-top: 185px;
	min-width: 900px;
}

hr {
	border: 0;
	border-top: 1px solid rgba(0,0,0,0.2);
}

a {
	color: $link-color;
	&:hover {
		text-decoration: underline;
	}
}

p {
	font-family: $font-family-base;
}