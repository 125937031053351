/* ==========================================================================
   Base
   ========================================================================== */

/**
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications. Removes the default spacing and border for
 * appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

iframe {
  border: 0;
}

ol,
ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

/**
 * Suppress the focus outline on links that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex="-1"] {
  outline: none;
}
