.u-cf {
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
}

.u-pull-start {
  float: left;
}

.u-pull-end {
  float: right;
}

.u-relative {
  position: relative;
}