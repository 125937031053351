@import "variables";

.u-color-link {
	color: $link-color;
}

.u-color-meta {
  color: rgba(0,0,0,0.5);
}

.u-color-blue-gray {
  color: $blue-gray;
}

.u-color-blue-gray-medium {
  color: $blue-gray-medium;
}

.u-color-blue-gray-dark {
  color: $blue-gray-dark;
}

.u-color-invert {
  color: white;
}