.artists__image {
  max-width: 100%;
}

.artists__release {
  width: 153px;
}

.artists__release-image {
  width: 100%;
}