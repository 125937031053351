$font-family-heading: Arvo, Times, serif;
$font-family-base: Helvetica Neue, Helvetica, Arial, sans-serif;

$font-size-xxlarge: 42px;
$font-size-xlarge: 30px;
$font-size-large: 20px;
$font-size-medium: 17px;
$font-size-small: 15px;
$font-size-xsmall: 13px;
$font-size-xxsmall: 12px;
$font-size-xxxsmall: 11px;

$space-large: 27px;
$space-xmedium: 22px;
$space-medium: 20px;
$space-small: 12px;
$space-xsmall: 9px;
$space-xxsmall: 6px;

$body-background-color: #c5efff;

$blue-gray: #B6DEEE;
$blue-gray-medium: #8FB3C2;
$blue-gray-dark: #7D9CA9;

$link-color: #000;