//////////////////////////
// Link-specific utilities
//////////////////////////
@import "variables";

// Link clean
//
// A link without any text-decoration at all.

.u-link-clean {
  text-decoration: none;
}

.u-link-hover-clean,
.u-link-hover-clean:hover {
  text-decoration: none;
}


